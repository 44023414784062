<template>
  <!--   保租房发布弹框       -->
  <r-e-dialog title="发布" :visible="dialogVisible" show-footer @click-submit="handleSubmitPublish"
              @click-cancel="handleCancel" @close="handleCancel" width="780px">
    <el-form ref="formPublish" label-width="110px" size="small" :model="formPublish" :rules="rules">
      <el-form-item label="地址" prop="name">
        <el-input disabled v-model="formPublish.name"/>
      </el-form-item>
      <el-form-item  v-if="rentalTypeName  === '固定金额'" label="月租金" prop="monthAmount">
        <el-input v-model="formPublish.monthAmount" :disabled="true" @change="sixMonthDiscountclieck">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item v-if="rentalTypeName  !== '固定金额'" label="单价" prop="monthPriceAmount">
        <el-input v-model="formPublish.monthPriceAmount" :disabled="true" @change="sixMonthDiscountclieck">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="面积" prop="insideArea">
        <el-input type="number" disabled v-model="formPublish.insideArea">
          <template slot="append">㎡</template>
        </el-input>
      </el-form-item>
<!--      <div class="flex">-->
<!--        <el-form-item label="半年租金折扣" prop="sixMonthDiscount">-->
<!--          <el-input type="number" v-model="formPublish.sixMonthDiscount">-->
<!--            <template slot="append">%</template>-->
<!--          </el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="半年租金">-->
<!--          <el-input disabled type="number" v-model="amountData.halfRentAmount">-->
<!--            <template slot="append">元/月</template>-->
<!--          </el-input>-->
<!--        </el-form-item>-->
<!--      </div>-->
<!--      <div class="flex">-->
<!--        <el-form-item label="半年押金">-->
<!--          <el-input disabled type="number" v-model="amountData.halfBoneAmount">-->
<!--            <template slot="append">元/月</template>-->
<!--          </el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="半年定金">-->
<!--          <el-input disabled type="number" v-model="amountData.halfDing">-->
<!--            <template slot="append">元/月</template>-->
<!--          </el-input>-->
<!--        </el-form-item>-->
<!--      </div>-->
<!--      <div class="flex">-->
<!--        <el-form-item label="年租金折扣" prop="yearDiscount">-->
<!--          <el-input type="number" v-model="formPublish.yearDiscount">-->
<!--            <template slot="append">%</template>-->
<!--          </el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="年租金">-->
<!--          <el-input disabled type="number" v-model="amountData.yearRentAmount">-->
<!--            <template slot="append">元/月</template>-->
<!--          </el-input>-->
<!--        </el-form-item>-->
<!--      </div>-->
<!--      <div class="flex">-->
<!--        <el-form-item label="年押金">-->
<!--          <el-input disabled type="number" v-model="amountData.yearBoneAmount">-->
<!--            <template slot="append">元/月</template>-->
<!--          </el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="年定金">-->
<!--          <el-input disabled type="number" v-model="amountData.yearDing">-->
<!--            <template slot="append">元/月</template>-->
<!--          </el-input>-->
<!--        </el-form-item>-->
<!--      </div>-->
      <div class="flex">
        <el-form-item label="保租房项目" style="width: 100%;">
          <el-select v-model="projectId" placeholder="请选择" filterable style="width: 100%;">
            <el-option v-for="item in projectList" :key="item.id" :label="item.project_name" :value="item.id"/>
          </el-select>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="">
<!--          <el-button type="primary" @click="getAmountData">计算金额</el-button>-->
        </el-form-item>
      </div>
    </el-form>
  </r-e-dialog>
</template>

<script>
import {ParseFloatFormat, ParseIntFormat} from "@custom/index";
import {MessageSuccess, MessageWarning} from "@custom/message";
import {apartmentDiscountAmountInfo, bzfGetAmount, getBzfProject, bzfProjectPut} from "@/api/house-resource";
import {publishApartment} from "@/api/publish";

export default {
  name: "layer-bzf-publish",
  data() {
    //自定义区间值校验
    let validatePass = (rule, value, callback) => {
      value = Number(value);
      if (value < this.percentageMin || value > this.percentageMax) {
        callback(new Error(`请输入${this.percentageMin}~${this.percentageMax}之间的数值`));
      } else if (value % 1 !== 0) {
        callback(new Error('请输入整数值'));
      } else {
        callback();
      }
    };
    return {
      rentalTypeName:null,//判断展示哪个类型，固定金额的月租
      percentageMin: null,
      percentageMax: null,
      dialogVisible: false,
      formPublish: {
        name: null,
        apartmentUuid: null,
        insideArea: null,
        outsideArea: null,
        monthAmount: null,
        // yearDiscount: null,
        // sixMonthDiscount: null,
        monthPriceAmount:null
      },
      rules: {
        monthAmount: [{required: true, message: "请输入单价", trigger: "blur"},],
        monthPriceAmount: [{required: true, message: "请输入单价", trigger: "blur"},],
        // sixMonthDiscount: [
        //   {required: true, message: "请输入半年折扣", trigger: "blur"},
        //   {validator: validatePass, trigger: 'blur'}
        // ],
        // yearDiscount: [{required: true, message: "请输入年折扣", trigger: "blur"},    {validator: validatePass, trigger: 'blur'}],
        outsideArea: [{required: true, message: "请输入建筑面积", trigger: "blur"},],
        insideArea: [{required: true, message: "请输入使用面积", trigger: "blur"},],
      },
      loadingOptions: {
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      },
      amountData: {
        // halfBoneAmount: null,
        // halfDing: null,
        // // halfRentAmount: null,
        // yearBoneAmount: null,
        // yearDing: null,
        // yearRentAmount: null,
      },
      projectId: null,
      projectList: []
    };
  },
  async created() {
    // await this.sixMonthDiscountclieck()
  },
  mounted() {
  },
  methods: {
    // 陈守亮-2023-11-24-固定发布折扣
    sixMonthDiscountclieck() {
      this.formPublish.sixMonthDiscount = 100
      // this.formPublish.yearDiscount = 100
      // console.log(this.formPublish)
    },
    async openDialog(data) {
      let that = this;
      // 陈守亮-2023-11-24-固定发布折扣
      await that.sixMonthDiscountclieck()
      let {name, uuid,percentageMax,percentageMin,rentalTypeName} = data;
      // alert(percentageMin)  monthAmount    monthPriceAmount
      this.percentageMax = percentageMax;
      this.percentageMin = percentageMin;
      this.rentalTypeName = rentalTypeName
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({...loadingOptions});
      let {info} = await apartmentDiscountAmountInfo({uuid});
      let res = await bzfGetAmount({uuid, monthPriceAmount: null, });
      // let {  yearBoneAmount, yearDing} = res.info;
      // halfBoneAmount = ParseFloatFormat(halfBoneAmount);
      // halfDing = ParseFloatFormat(halfDing);
      // halfRentAmount = ParseFloatFormat(halfRentAmount);
      // yearBoneAmount = ParseFloatFormat(yearBoneAmount);
      // yearDing = ParseFloatFormat(yearDing);
      // yearRentAmount = ParseFloatFormat(yearRentAmount);
      let { monthPriceAmount, outsideArea, insideArea,monthAmount} = info;
      // rentalTypeName
      monthPriceAmount = ParseFloatFormat(monthPriceAmount);
      monthAmount= ParseFloatFormat(monthAmount)
      // if(this.rentalTypeName !== '固定金额')
      this.formPublish = {
        name,
        apartmentUuid: uuid,
        insideArea,
        outsideArea,
        monthPriceAmount,
        // yearDiscount,
        monthAmount,

      };
      // that.amountData = {  yearBoneAmount, yearDing,};

      const {msg} = await getBzfProject();
      const {result} = JSON.parse(msg)
      // console.log(result);
      that.projectList = result;
      loading.close();
      this.dialogVisible = true;
    },
    // 发布确认事件
    handleSubmitPublish() {
      let that = this;
      // 表单提交
      this.$refs.formPublish.validate((valid) => {
        if (valid) {
          let {apartmentUuid, insideArea, outsideArea, monthAmount,  monthPriceAmount} = that.formPublish;
          monthAmount = ParseIntFormat(monthAmount);
          monthPriceAmount = ParseIntFormat(monthPriceAmount);
          let params = {apartmentUuid, insideArea, outsideArea,  };
          // 区分小区进行附加不同参数
          if(that.rentalTypeName === '固定金额'){
            params.monthAmount = monthAmount
          }else {
            params.monthPriceAmount = monthPriceAmount
          }
          publishApartment(params).then(res => {
            MessageSuccess(`${that.formPublish.name} 发布成功!`);
            that.handleCancel("update");
          });
        } else {
          MessageWarning("请填写必填值");
          return false;
        }
      });
    },
    handleCancel(closeType = "cancel") {
      this.$refs["formPublish"].resetFields();
      // 取消、关闭弹窗
      this.dialogVisible = false;
      this.$emit("handleSearch");
    },
    // 计算金额
    async getAmountData() {
      let that = this;
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({...loadingOptions});
      let {apartmentUuid: uuid, monthAmount,} = that.formPublish;
      monthAmount = ParseIntFormat(monthAmount);
      // let res = await bzfGetAmount({uuid, monthAmount, yearDiscount,});
      // let {halfBoneAmount, halfDing, yearBoneAmount, yearDing, yearRentAmount} = res.info;
      // halfBoneAmount = ParseFloatFormat(halfBoneAmount);
      // halfDing = ParseFloatFormat(halfDing);
      // // halfRentAmount = ParseFloatFormat(halfRentAmount);
      // yearBoneAmount = ParseFloatFormat(yearBoneAmount);
      // yearDing = ParseFloatFormat(yearDing);
      // yearRentAmount = ParseFloatFormat(yearRentAmount);
      // that.amountData = {halfBoneAmount, halfDing, yearBoneAmount, yearDing, yearRentAmount};
      loading.close();
    }
  },

  watch: {
    projectId(value) {
      if (value) {
        let {apartmentUuid: uuid} = this.formPublish;
        bzfProjectPut({uuid, projectId: value});
      }
    }
  }
}
</script>

<style scoped>

</style>
